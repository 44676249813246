import React from 'react'
import styled from 'styled-components'
import {FlexEvenly, FlexColumn} from './shared/styles/flex'
import InstaIcon from '../images/instagram-icon.svg'
import TwitterIcon from '../images/twitter-icon.svg'

const StyledFooter = styled.footer`
  background: #1e272e;
  color: #FFF;
  
  h1 {
    color: #FFFFFF;
    font-size: 21px;
  }
  
  a {
    color: #FFFFFF;
    font-size: 16px;
  }
  
  .icons {
    width: 30px;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #444;
  padding: 3em 0;
  height: 62%;
  display: flex;
`

const StyledSocial = styled(FlexEvenly)`
  height: 38%;
  width: 175px;
  padding: 2em;
  margin: auto;
`

const StyledFooterCol = styled(FlexColumn)`
  text-align: left;
  margin-bottom: 2em;
`

const StyledLinks = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  margin: auto;
  
  @media only screen and (max-width: 1000px) {
    width: 75%;
  }
  
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 2em;
  }
  
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    width: 60%;
    padding: 0;
  }
`

export default function Footer() {

  return (
    <StyledFooter>
      {/*<Navigation/>*/}
      <StyledContainer>
        <StyledLinks>
          <StyledFooterCol>
            <h1>FAQ</h1>
            <a href="https://blog.pairdot.com/what-are-nfts/">What Are NFT's?</a>
            <a href="https://blog.pairdot.com/what-is-ipfs-and-what/">What is IPFS?</a>
            <a href="https://blog.pairdot.com/how-do-i-create-an-nft/">How Do I Create NFT's?</a>
            <a href="https://blog.pairdot.com/nfts-non-fungible-token-available-offline/">Are NFT's Available Offline?</a>
            <a href="https://blog.pairdot.com/nft-encryption/">Are NFT's Encrypted?</a>
          </StyledFooterCol>
          <StyledFooterCol>
            <h1>Contact</h1>
            <a href="mailto:info@pairdot.com">info@pairdot.com</a>
            <a href="https://blog.pairdot.com/">Blog</a>
          </StyledFooterCol>
          <StyledFooterCol>
            <h1>Go Pro</h1>
            <a href="https://payments.pabbly.com/subscribe/6063ef0ba0968524bce7167f/beginner-plan">Encrypt your NFT's</a>
          </StyledFooterCol>
        </StyledLinks>
      </StyledContainer>
      <StyledSocial>
        <a href="https://twitter.com/pairdotcrypto" target="_blank" rel="noreferrer"><img className="icons twitter-icon" src={TwitterIcon} alt="twitter-icon" /></a>
        <a href="https://instagram.com/pairdotcrypto" target="_blank" rel="noreferrer"><img className="icons" src={InstaIcon} alt="instagram-icon" /></a>
      </StyledSocial>
    </StyledFooter>
  )
}

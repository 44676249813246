import React, { ChangeEvent, useState } from 'react'
import { Upload, message } from 'antd'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { CircularProgress, Tooltip, Typography } from '@material-ui/core'
import { config } from 'config/config'
import {
  StyledCTA,
  StyledContainer,
  StyledH2,
  StyledFormContainer,
  StyledButton,
  StyledUploadButton,
  UploadContainer,
  StyledCopyButton,
  StyledLinkAccessField,
  StyledAccessLink,
  StyledRestrictedLinkH2,
} from './styles'
import HelpIcon from '@material-ui/icons/Help'
import { postApi } from '@pairdot/common/api'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
// import { useSubscribed } from '../../hooks/useSubscribed'
import Flex from '@pairdot/common/src/components/Flex'
import { Gutter } from '../styles'
import { useAtomValue } from 'jotai/utils'
import { currentSessionAtom } from '../Auth'
import { AxiosResponse } from 'axios'

function TabPanel({ children, value, index, ...other }: any) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  )
}

const { Dragger } = Upload

const Uploader = () => {
  const [shortUrlCode, setShortUrlCode] = useState('')
  const [fileName, setFileName] = useState('')
  const [disableCreateButton, setDisableCreateButton] = useState(true)
  const [loading, setLoading] = useState(false)
  const [restrictedAccessCheck, setRestrictedAccessCheck] = useState(false)
  const [directoryCheck /**setDirectoryCheck*/] = useState(false)
  const [passwordProtectedCheck, setPasswordProtectedCheck] = useState(false)
  const [restrictedLink, setRestrictedLink] = useState('')
  const [displayRestrictedLink, setDisplayRestrictedLink] = useState(false)
  const [accessToken, setAccessToken] = useState('')
  const [tab, setTab] = useState(0)
  const currentSession = useAtomValue(currentSessionAtom)
  const currentUsersEmail = (currentSession as any)?.idToken?.payload?.email
  // const isSubscribed = useSubscribed()
  console.log('***val-restricted-link', restrictedLink)

  /**
   * Responsible for setting the fileName state and notifying the user of the upload status
   *
   * @param info
   */
  function handleChange(info: any) {
    const { status, response } = info.file

    if (status !== 'uploading') console.log(info.file, info.fileList)

    if (status === 'done') {
      message.success('your file has uploaded')
      setDisableCreateButton(false)
      setFileName(response.fileName)
    } else if (status === 'error') {
      message.error('there was an error uploading your file')
    }
  }

  /**
   * Verify the file is less than 20MB
   *
   * @param file
   */
  function beforeUpload(file: any) {
    const isLessThanOneGB = file.size / 1024 / 1024 < 1000

    if (!isLessThanOneGB) message.error('asset must be smaller than 1GB!')

    return isLessThanOneGB
  }

  /**
   * Upload Dragger props
   *
   * action - responsible for uploading the file to the server
   */
  const props = {
    name: 'token',
    multiple: true,
    action: `${config.api}/api/upload-file`,
    onChange: (info: any) => handleChange(info),
    beforeUpload: (file: any) => beforeUpload(file),
  }

  /**
   * Create an asset token from the file uploaded
   */
  async function handleIpfsFileUpload() {
    const apiUrl = restrictedAccessCheck ? `${config.api}/api/restrict-link-access` : `${config.api}/api/token`
    setLoading(true)
    try {
      const response = await postApi(apiUrl, {
        fileName: fileName,
        email: currentUsersEmail,
      })
      setShortUrlCode(response?.data.shortUrl)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  async function handleGenerateAccessToken() {
    setLoading(true)
    try {
      const response: AxiosResponse<{ accessToken: string; longUrl: string }> = await postApi(
        `${config.api}/api/generate-restricted-link-access-token`,
        {
          url: restrictedLink,
          email: currentUsersEmail,
        }
      )
      console.log('***val-access-token-response', response)
      if (response) {
        setAccessToken(response?.data.accessToken)
        setRestrictedLink(response.data.longUrl)
        setDisplayRestrictedLink(true)
      } else {
        throw Error('error generating access token')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  // /**
  //  * Used for subscribed users. Create an NFT with a randomly generated password
  //  */
  // async function createFileWithPassword() {
  //   try {
  //     const res = await postApi(`${config.api}/api/password-token`, {
  //       fileName: fileName,
  //       email: currentUsersEmail,
  //     })
  //
  //     if (res) {
  //       console.log('res', res)
  //       setShortUrlCode(res.data.IpfsHash)
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  /**
   * Copy the smart contract crypto to the clipboard
   */
  function copyToClipboard() {
    const el = document.createElement('textarea')
    el.value = shortUrlCode
    document.body.appendChild(el)
    el.select()
    el.setSelectionRange(0, 99999)
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  return (
    <StyledFormContainer className="flex">
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, newValue) => setTab(newValue)}
        aria-label="disabled tabs example"
      >
        <Tab label="Public Link" />
        <Tab label="Share Restricted Link" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Gutter top margin="2em">
          <UploadContainer>
            <Dragger {...props} directory={directoryCheck}>
              <StyledContainer>
                <StyledUploadButton>
                  <CloudUploadIcon />
                </StyledUploadButton>
              </StyledContainer>
            </Dragger>
          </UploadContainer>
        </Gutter>
        <FormGroup>
          <Flex center gap="12px">
            {/*<FormControlLabel*/}
            {/*  control={*/}
            {/*    <>*/}
            {/*      <Checkbox*/}
            {/*        color="primary"*/}
            {/*        checked={directoryCheck}*/}
            {/*        onChange={(e: ChangeEvent<HTMLInputElement>) => setDirectoryCheck(e.target.checked)}*/}
            {/*        inputProps={{ 'aria-label': 'restricted access checkbox' }}*/}
            {/*      />*/}
            {/*    </>*/}
            {/*  }*/}
            {/*  label="upload folder"*/}
            {/*/>*/}
            <FormControlLabel
              disabled={!currentUsersEmail}
              control={
                <>
                  <Checkbox
                    color="primary"
                    disabled={!currentUsersEmail}
                    checked={restrictedAccessCheck}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setRestrictedAccessCheck(e.target.checked)}
                    inputProps={{ 'aria-label': 'restricted access checkbox' }}
                  />
                  <Tooltip
                    title="Create a link that is not visible to the public. Share by creating a temporary access link by going to the Share Restricted Link tab"
                    enterTouchDelay={0}
                  >
                    <HelpIcon color={currentUsersEmail ? 'secondary' : 'primary'} style={{ marginRight: 6 }} />
                  </Tooltip>
                </>
              }
              label="restrict link access"
            />
            {config.featureFlag.encrypt && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={passwordProtectedCheck}
                    onChange={(e) => setPasswordProtectedCheck(e.target.checked)}
                    inputProps={{ 'aria-label': 'restricted access checkbox' }}
                  />
                }
                label="Password Protect File"
              />
            )}
          </Flex>
        </FormGroup>
        <Flex center style={{ marginTop: '18px' }}>
          {!loading ? (
            <StyledButton disabled={disableCreateButton} onClick={handleIpfsFileUpload} variant="contained">
              get link
            </StyledButton>
          ) : (
            <CircularProgress style={{ margin: 'auto', marginBottom: '2em' }} />
          )}
        </Flex>
        <div>
          {shortUrlCode && (
            <div style={{ marginTop: '18px' }}>
              <StyledH2>
                <a href={shortUrlCode} rel="noreferrer" target="_blank">
                  <div>{shortUrlCode.split('https://').join('')}</div>
                </a>
              </StyledH2>
              <StyledCTA>
                <StyledCopyButton onClick={copyToClipboard}>copy</StyledCopyButton>
              </StyledCTA>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Gutter top>
          <Flex direction="column" center style={{ marginTop: '3em' }} gap="24px">
            <div>
              <h3 style={{ color: '#555' }}>Create Restricted Link Access</h3>
              <h5 style={{ color: '#999' }}>
                <i>expires in one day</i>
              </h5>
            </div>
            <StyledLinkAccessField
              onChange={(e: any) => {
                setRestrictedLink(e.target.value)
                setDisplayRestrictedLink(false)
              }}
              id="outlined-basic"
              label="restricted link"
              variant="outlined"
            />
            {!loading ? (
              <StyledButton onClick={handleGenerateAccessToken} disabled={!restrictedLink} variant="contained">
                Get Link
              </StyledButton>
            ) : (
              <CircularProgress style={{ margin: 'auto', marginBottom: '2em' }} />
            )}
            {displayRestrictedLink && (
              <StyledRestrictedLinkH2>
                <StyledAccessLink
                  target="_blank"
                  rel="noreferrer"
                  href={`${restrictedLink}?accessToken=${accessToken}`}
                >
                  {restrictedLink}?accessToken={accessToken}
                </StyledAccessLink>
              </StyledRestrictedLinkH2>
            )}
          </Flex>
        </Gutter>
      </TabPanel>
    </StyledFormContainer>
  )
}

export default Uploader

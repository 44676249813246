import React, { useEffect } from 'react'
import Uploader from './components/Uploader'
import Footer from '@pairdot/common/src/components/Footer'
import Navigation from './components/Navigation'
import Auth from './components/Auth'
import 'antd/dist/antd.css'
import './App.css'
import { StyledApp } from 'components/shared/styles/appStyles'
import BusinessInsiderLogo from './images/business-insider-logo.png'
import YahooFinanceLogo from './images/yahoo-finance-logo.png'
import NftEveningLogo from './images/NFTevening-logo.png'
import styled from 'styled-components'
import { postApi } from '@pairdot/common/api'
import { config } from './config/config'
import Flex from '@pairdot/common/src/components/Flex'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LinkIcon from '@material-ui/icons/Link'
import ShareIcon from '@material-ui/icons/Share'

const StyledPrBadges = styled.div`
  padding: 1em;
  background: #1e272e;
  border-bottom: 1px solid #444;

  .pr-container {
    max-width: 1400px;
    margin: auto;

    img {
      width: 175px;

      @media only screen and (max-width: 1000px) {
        width: 175px;
      }

      @media only screen and (max-width: 700px) {
        width: 150px;
      }

      @media only screen and (max-width: 525px) {
        width: 100px;
      }

      @media only screen and (max-width: 465px) {
        width: 90px;
      }
    }
  }
`

const PrBadgesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .filter {
    filter: brightness(0) invert(1);
  }

  .filter-nft-evening {
    filter: invert(1);
  }
`

const StyledInfoContainer = styled.div`
  margin-left: 24px;
  height: 100%;
  margin-top: 133px;
  text-align: left;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
  h2 {
    font-size: 32px;
  }

  > * {
    color: ghostwhite;
    //filter: drop-shadow(1px 1px 2px #111);
  }

  @media only screen and (max-width: 1040px) {
    margin: 0px;
    h1 {
      font-size: 48px;
    }
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 150px;
    h1 {
      font-size: 48px;
    }
  }

  @media only screen and (max-width: 1040px) {
    margin-top: 0;
    h1 {
      font-size: 48px;
    }
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 24px;
    }
  }
`

const App = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', async () => await postApi(`${config.api}/api/clean-up-uploads`))
  }, [])

  return (
    <StyledApp>
      <div className="nav">
        <Navigation />
        <Auth />
      </div>
      <div className="main-body">
        <Flex style={{ height: '100%' }} column>
          <StyledInfoContainer>
            <h1 style={{ marginBottom: 18 }}>
              Share files easily
              <br />
              on the blockchain
            </h1>
            <h2>
              1. upload a file <CloudUploadIcon style={{ color: '#fedd58' }} />
            </h2>
            <h2>
              2. get a link <LinkIcon style={{ color: '#fedd58' }} />
            </h2>
            <h2>
              3. share <ShareIcon style={{ color: '#fedd58' }} />
            </h2>
            <h2 style={{ marginTop: 18 }}>
              Links can be restricted or public,
              <br /> shared, or simply stored as NFTs.
              <br />
            </h2>
          </StyledInfoContainer>
        </Flex>
        <Uploader />
      </div>
      <div>
        <StyledPrBadges>
          <PrBadgesContainer className="pr-container">
            <a
              className="filter"
              href="https://finance.yahoo.com/finance/news/pairdot-launches-first-ever-environmentally-212500104.html"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="yahoo-finance-logo" src={YahooFinanceLogo} />
            </a>
            <a
              className="filter"
              href="https://markets.businessinsider.com/news/stocks/pairdot-launches-the-first-ever-environmentally-friendly-and-mobile-nft-application-1030530084"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="business-insider-logo" src={BusinessInsiderLogo} />
            </a>
            <a
              href="https://nftevening.com/pairdots-green-mobile-nft-app-launches-with-free-minting/"
              className="filter-nft-evening"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="yahoo-finance-logo" src={NftEveningLogo} />
            </a>
          </PrBadgesContainer>
        </StyledPrBadges>
      </div>
      <Footer />
    </StyledApp>
  )
}

export default App

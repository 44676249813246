import axios from 'axios'

const headers = {
  'Content-Type': 'application/json'
}

export async function getApi(url: string): Promise<any> {
  try {
    return await axios.get(url, { headers: headers })
  } catch(e) {
    console.error(e)
  }
}

export async function postApi(url: string, data?: any): Promise<any> {
  try {
    return await axios.post(url, data, { headers: headers })
  } catch(e) {
    console.error(e)
  }
}

export async function patchApi(url: string, data?: any): Promise<any> {
  try {
    return await axios.patch(url, data, { headers: headers })
  } catch(e) {
    console.error(e)
  }
}

export async function deleteApi(url: string): Promise<any> {
  try {
    return await axios.delete(url, { headers: headers })
  } catch(e) {
    console.error(e)
  }
}

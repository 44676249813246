import React, { Suspense } from 'react'
import DesktopNavigation from 'components/Navigation/Desktop'
import MobileNavigation from 'components/Navigation/Mobile'
import { atom } from 'jotai'
import { CircularProgress } from '@material-ui/core'

export const openLoginModalAtom = atom(false)

export default function Navigation() {
  return (
    <>
      <Suspense fallback={<CircularProgress style={{ margin: 'auto', marginBottom: '2em' }} />}>
        <DesktopNavigation />
      </Suspense>
      <MobileNavigation />
    </>
  )
}

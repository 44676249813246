import React, { useRef, useEffect } from 'react'
import { useUpdateAtom } from 'jotai/utils'
import { openLoginModalAtom } from './Navigation'

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideEventEmitter(ref: any) {
  const updateOpenLoginModal = useUpdateAtom(openLoginModalAtom)
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        updateOpenLoginModal(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, updateOpenLoginModal])
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideEventEmitter({ children }: { children: React.ReactNode }) {
  const wrapperRef = useRef(null)
  useOutsideEventEmitter(wrapperRef)

  return <div ref={wrapperRef}>{children}</div>
}

export default OutsideEventEmitter

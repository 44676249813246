import React, { useEffect, useState } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import styled from 'styled-components'
import { useUpdateAtom } from 'jotai/utils'
// import { openLoginModalAtom } from './Navigation'
import { Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Avatar from '@material-ui/core/Avatar'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import OutsideEventEmitter from './OutsideEventEmitter'
import { atom, useAtom } from 'jotai'
import { StyledLoginButton } from './Uploader/styles'
import { openLoginModalAtom } from './Navigation'

const awsExportsWithEnv = {
  aws_project_region: 'us-east-2',
  aws_cognito_region: 'us-east-2',
  oauth: {
    domain: process.env.REACT_APP_domain,
    redirectSignIn: process.env.REACT_APP_redirect_sign_in,
    redirectSignOut: process.env.REACT_APP_redirect_sign_out,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    responseType: 'code',
  },
  aws_cognito_identity_pool_id: process.env.REACT_APP_aws_cognito_identity_pool_id,
  aws_user_pools_id: process.env.REACT_APP_aws_user_pools_id,
  aws_user_pools_web_client_id: process.env.REACT_APP_aws_user_pools_web_client_id,
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: [],
  // aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
}

const StyledEmail = styled.h3`
  width: 100%;
  color: #555;
  font-weight: bold;
  margin: 0px;
  text-align: center;
`

const StyledAuthenticator = styled(Authenticator)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 400px;
  [data-amplify-router] {
    border: none;
    box-shadow: none;
  }
`

const StyledButton: any = withStyles({
  root: {
    width: 150,
    background: '#6c5ce7',
    color: '#FFFFFF',
    marginLeft: 18,
    marginRight: 18,
  },
})(Button)

const StyledIconButton: any = withStyles({
  root: {
    width: (props: { width: string }) => props.width,
    marginRight: '1em',
  },
})(IconButton)

Amplify.configure(awsExportsWithEnv)

export const userAuthAtom = atom(false)
export const currentSessionAtom: any = atom(undefined)

const Flex = styled.div`
  display: flex;
`

function Authentication() {
  const [authenticated, setAuthenticated] = useState(false)
  const [currentSession, setCurrentSession] = useAtom(currentSessionAtom)
  // const openLoginModal = useAtomValue(openLoginModalAtom)
  const updateUserAuthAtom = useUpdateAtom(userAuthAtom)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    const amplifyAuthenticated = async () => setAuthenticated(await Auth.currentAuthenticatedUser())
    if (!authenticated) amplifyAuthenticated()
  }, [authenticated])

  useEffect(() => {
    const amplifyCurrentSession = async () => {
      setCurrentSession(await Auth.currentSession())
      updateUserAuthAtom(true)
    }
    if (!currentSession) amplifyCurrentSession()
  }, [currentSession, updateUserAuthAtom, setCurrentSession])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const updateOpenLoginModal = useUpdateAtom(openLoginModalAtom)

  return (
    <OutsideEventEmitter>
      <Flex>
        <Tooltip title="Account">
          <StyledIconButton
            style={{ background: 'transparent' }}
            onClick={handleClick}
            width={!authenticated && '180px'}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {!authenticated ? (
              <div className="link">
                <StyledLoginButton className="link-style" variant="text" onClick={() => updateOpenLoginModal(true)}>
                  signup / login
                </StyledLoginButton>
              </div>
            ) : (
              <Avatar style={{ backgroundColor: '#6b5ce7' }} />
            )}
          </StyledIconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {authenticated && currentSession && (
            <MenuItem>
              <StyledEmail>{(currentSession as any)?.idToken?.payload?.email}</StyledEmail>
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={(e) => e.stopPropagation()} style={{ background: '#FFFFFF' }}>
            {!authenticated && <div style={{ width: 448, height: 500, background: '#FFFFFF' }} />}
            <StyledAuthenticator>
              {({ signOut }) => {
                const handleSignOut = () => {
                  updateUserAuthAtom(false)
                  signOut && signOut()
                }
                return (
                  <MenuItem>
                    <StyledButton variant="contained" onClick={handleSignOut}>
                      Sign out
                    </StyledButton>
                  </MenuItem>
                )
              }}
            </StyledAuthenticator>
          </MenuItem>
        </Menu>
      </Flex>
    </OutsideEventEmitter>
  )
}

export default Authentication

import styled from 'styled-components'
import EcoImage from 'images/eco-friendly-background-min.png'
import MobileImage from 'images/pairdot-mobile-friendly-min.png'

const GRADIENT_COLOR_1 = '#02e1ba 0%'
const GRADIENT_COLOR_2 = '#26c9f2 29%'
const GRADIENT_COLOR_3 = '#d911f2 66%'
const GRADIENT_COLOR_4 = '#ffa079 100%'

export const StyledApp = styled.div`
  .ant-upload {
    width: 100% !important;
    height: 200px !important;
    margin: auto !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 10px !important;
  }

  .App-logo {
    height: 40vmin;
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #09d3ac;
  }

  .nav {
    position: absolute;
    width: 100%;
    padding: 1em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-body {
    //background: linear-gradient(45deg, ${GRADIENT_COLOR_1}, ${GRADIENT_COLOR_2}, ${GRADIENT_COLOR_3}, ${GRADIENT_COLOR_4});
    background: linear-gradient(45deg, #0097e6 0%, #007cbd 60%, #26c9f2 50%, #0097e6d6 100%);
    background-size: 400% 400%;
    animation: Gradient 255s ease infinite;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
  }

  .main-body::before, 
  //.main-body::after {
  //  content: "";
  //  width: 70vmax;
  //  height: 70vmax;
  //  position: absolute;
  //  background: rgba(255, 255, 255, 0.07);
  //  left: -20vmin;
  //  top: -20vmin;
  //  animation: morph 225s linear infinite alternate, spin 20s linear infinite;
  //  z-index: 1;
  //  will-change: border-radius, transform;
  //  transform-origin: 55% 55%;
  //  pointer-events: none; 
  //}

  .main-body::after {
    width: 70vmin;
    height: 70vmin;
    left: auto;
    right: -10vmin;
    top: auto;
    bottom: 0;
    animation: morph 55s linear infinite alternate, spin 26s linear infinite reverse;
    transform-origin: 20% 20%;
  }

  @keyframes Gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @keyframes morph {
    0% {
      border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
    }
    100% {
      border-radius: 40% 60%;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
  .st0 {
    display: none;
  }
  .st1 {
    display: inline;
  }
  .st2 {
    opacity: 0.29;
  }
  .st3 {
    fill: #ffffff;
  }
  .st4 {
    clip-path: url(#SVGID_2_);
    fill: #ffffff;
  }
  .st5 {
    clip-path: url(#SVGID_4_);
  }
  .st6 {
    clip-path: url(#SVGID_6_);
  }
  .st7 {
    clip-path: url(#SVGID_8_);
  }
  .st8 {
    clip-path: url(#SVGID_10_);
  }
  .st9 {
    fill: none;
  }
  .st10 {
    clip-path: url(#SVGID_12_);
  }
  .st11 {
    opacity: 0.7;
  }
  .st12 {
    clip-path: url(#SVGID_14_);
  }
  .st13 {
    opacity: 0.2;
  }
  .st14 {
    clip-path: url(#SVGID_16_);
  }
  .st15 {
    opacity: 0.3;
    fill: #ffffff;
    enable-background: new;
  }

  .main-body {
    background-position-y: 50%;
    background-position-x: -200px;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1040px) {
      flex-direction: column-reverse;
      height: 100%;
      padding-top: 150px;
      padding-bottom: 24px;
    }
    }
  }

  .eco-friendly-info,
  .pairdot-mobile-friendly-info {
    height: 100%;
    padding: 3em 0;
    background-size: cover;
  }

  .eco-friendly-info {
    background-image: url(${EcoImage});
    background-position-x: -400px;
  }

  .pairdot-mobile-friendly-info {
    background-image: url(${MobileImage});
  }

  .svg {
    position: absolute;
    left: 0;
  }

  @media only screen and (max-width: 768px) {
    .eco-friendly-info {
      margin-top: 0px;
    }

    .main-.main-body {
      padding-bottom: 5em;
    }

    .ant-upload {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 620px) {
    .pairdot-mobile-friendly-info {
      background-position-x: -725px;
      background-repeat: no-repeat;
    }
  }
`

export const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3em 10em;
  text-align: left;

  &.mobile {
    min-height: 1000px;
  }

  h1,
  h2 {
    color: #ffffff;
    width: 62%;
  }

  h2 {
    font-size: 30px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 3em;

    h1,
    h2 {
      width: 100%;
    }

    h1 {
      font-size: 36px;
    }
  }

  @media only screen and (max-width: 620px) {
    text-align: center;
    h1,
    h2 {
      background: #000000;
    }
  }
`

import React from 'react'

const marginDefault = '18px'
export const Gutter = ({
  margin = marginDefault,
  children,
  top,
  right,
  bottom,
  left,
  all,
}: {
  margin?: string
  children: React.ReactNode
  top?: boolean
  right?: boolean
  bottom?: boolean
  left?: boolean
  all?: boolean
}) => (
  <div
    style={{
      marginTop: top || all ? margin : '',
      marginRight: right || all ? margin : '',
      marginBottom: bottom || all ? margin : '',
      marginLeft: left || all ? margin : '',
    }}
  >
    {children}
  </div>
)

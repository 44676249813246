import styled from 'styled-components'

export const Flex = styled.div`
  display: flex;
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`

export const FlexCenter = styled(Flex)`
  justify-content: center;
  align-items: center;
`

export const FlexEvenly = styled(Flex)`
  justify-content: space-evenly;
  align-items: center;
`

import React from 'react'
import styled from 'styled-components'
import PairdotLogo from 'images/pairdot_logo.svg'

const StyledMenu = styled.menu<{ justifyContent?: string }>`
  padding: 0;
  margin: 0;
  display: flex;
  padding-left: 2em;
  align-items: center;
  width: 100%;
  font-size: 18px;
  text-transform: lowercase;
  justify-content: ${(props) => props.justifyContent};

  a {
    font-weight: bold;
    color: #ffffff;
    filter: drop-shadow(1px 1px 2px #333);
    text-transform: uppercase;
  }

  img {
    width: 50px;
    margin-right: 15px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
    font-size: 18px;
    margin-top: -50px;
    width: 100%;

    .pairdot-link {
      display: none;
    }

    .blog-link {
      padding-left: 0px;
    }
  }

  > * {
    padding-right: 9px;
    padding-left: 9px;
  }

  .link {
    margin-right: 18px;
    padding-right: 18px;
  }

  @media only screen and (max-width: 940px) {
    display: none;
  }
`

const FlexCenter = styled.div`
  color: white;
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: bold;
  filter: drop-shadow(1px 1px 2px #555);
`

export default function DesktopNavigation() {
  return (
    <StyledMenu>
      <FlexCenter>
        <img src={PairdotLogo} alt="pairdot logo" />
        pairdot
      </FlexCenter>
      <StyledMenu justifyContent="flex-end">
        <div className="link separator">
          <a
            className="link-style"
            target="_blank"
            rel="noreferrer"
            href="https://payments.pabbly.com/subscribe/6063ef0ba0968524bce7167f/beginner-plan"
          >
            subscribe
          </a>
        </div>
        <div className="link">
          <a href="https://qreate.pairdot.com">QReate</a>
        </div>
        <div className="link">
          <a href="https://blog.pairdot.com">Blog</a>
        </div>
        <div className="link">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://chrome.google.com/webstore/detail/pairdot-mint-nfts/dmpibfofknbfjellfbhicomgcpeemhfb?hl=en&authuser=1"
          >
            Chrome Extension
          </a>
        </div>
      </StyledMenu>
    </StyledMenu>
  )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Link } from '@material-ui/core'
import Hamburger from 'hamburger-react'
import withStyles from '@material-ui/core/styles/withStyles'

const fonSize = '24px'

export const StyledLink: any = withStyles({
  root: {
    color: 'initial',
    textDecoration: 'none',
  },
})(Link)
const MobileNavStyle = styled.div`
  display: none;

  @media only screen and (max-width: 940px) {
    display: block;
    width: 100%;
    display: flex;

    a {
      color: #333;
    }
  }
`

const Container = styled.div<{ width: number }>`
  .overlay {
    height: 100%;
    width: ${(props) => props.width}%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #6c5ce7;
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-content {
    width: ${(props) => props.width}%;
    text-align: center;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: ${fonSize};
    color: #ffffff;
    display: block;
    transition: 0.3s;
  }

  .overlay a:hover,
  .overlay a:focus {
    color: #f1f1f1;
  }

  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

  @media screen and (max-height: 450px) {
    .overlay a {
      font-size: 20px;
    }
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }
`

const StyledHamburger: any = withStyles({
  root: {
    zIndex: 2,
    marginLeft: '1em',
  },
})(Button)

export default function MobileNavigation() {
  const [isOpen, setOpen] = useState(false)
  const [width, setWidth] = useState(0)

  const handleClose = () => {
    setOpen(false)
    setWidth(0)
  }

  useEffect(() => {
    setWidth(!isOpen ? 0 : 100)
  }, [isOpen, setWidth])

  return (
    <>
      <Container width={width}>
        <div id="myNav" className="overlay">
          <div className="overlay-content">
            <Button className="link-style">
              <StyledLink
                target="_blank"
                rel="noreferrer"
                href="https://payments.pabbly.com/subscribe/6063ef0ba0968524bce7167f/beginner-plan"
              >
                subscribe
              </StyledLink>
            </Button>
            <Button onClick={handleClose}>
              <StyledLink href="https://qreate.pairdot.com">QReate</StyledLink>
            </Button>
            <Button onClick={handleClose}>
              <StyledLink href="https://blog.pairdot.com">Blog</StyledLink>
            </Button>
            <Button onClick={handleClose}>
              <StyledLink
                target="_blank"
                rel="noreferrer"
                href="https://chrome.google.com/webstore/detail/pairdot-mint-nfts/dmpibfofknbfjellfbhicomgcpeemhfb?hl=en&authuser=1"
              >
                Chrome
              </StyledLink>
            </Button>
          </div>
        </div>
      </Container>
      <MobileNavStyle>
        <StyledHamburger onClick={() => setOpen(!isOpen)}>
          <Hamburger color="#FFFFFF" toggled={isOpen} />
        </StyledHamburger>
      </MobileNavStyle>
    </>
  )
}

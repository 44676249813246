import React from 'react'
import styled from 'styled-components'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type FlexProps = {
  children?: React.ReactNode
  direction?: 'column' | 'row'
  justifyContent?: string
  alignItems?: string
  gap?: string
  center?: boolean
  style?: CSSProperties
  column?: boolean
}

const StyledFlex = styled.div<FlexProps>`
  display: flex;
  ${(props) => props.direction && `flex-direction: ${props.direction};`}
  ${(props) => props.column && `flex-direction: column;`}
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.gap && `gap: ${props.gap};`}
  ${(props) =>
    props.center &&
    `
    align-items: center;
    justify-content: center;
  `}
`

export default function Flex({
  children,
  direction,
  justifyContent,
  alignItems,
  gap,
  center,
  style,
  column,
}: FlexProps) {
  return (
    <StyledFlex
      center={center}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      style={{ ...style }}
      column={column}
    >
      {children}
    </StyledFlex>
  )
}

import styled from 'styled-components'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, TextField } from '@material-ui/core'

export const UploadContainer = styled.div`
  width: 100%;
  margin-bottom: 2em;
`

export const StyledUploadButton: any = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '120px',
  },
})(Button)

export const StyledButton: any = withStyles({
  root: {
    background: '#575fcf',
    color: '#FFF',
    height: '36px',
    width: '132px',
    borderRadius: '50px',
  },
})<any>(Button)

export const StyledIcon = styled.div`
  color: #f6b93b;
`

export const StyledProText = styled.span`
  font-size: 0.75rem;
  cursor: pointer;
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledHeader = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2em;
  border-radius: 10px;

  h1,
  h2 {
    color: #ffffff;
  }

  h2 {
    font-size: 30px;

    @media only screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  h1 {
    font-size: 72px;
    font-weight: bold;
    margin: 0;
  }
`
export const StyledCTA = styled.div`
  margin-top: 0.5em;
  display: flex;
  justify-content: space-around;

  p {
    color: #ff6b81;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 1em;
  }
`

export const StyledCopyButton: any = withStyles({
  root: {
    color: '#777',
    fontSize: '12px',
  },
})(Button)

export const StyledFormContainer = styled.div`
  min-height: 440px;
  filter: drop-shadow(1px 1px 5px #333);
  max-width: 500px;
  display: block;
  padding: 12px 2em;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  justify-content: space-evenly;

  @media only screen and (max-width: 1040px) {
    filter: drop-shadow(1px 1px 5px #444);
    flex-direction: column;
    margin-bottom: 3em;
  }

  @media only screen and (max-width: 500px) {
    filter: none;
    border-radius: 0;
  }
`

export const StyledAccessLink = styled.a`
  width: 350px;
  overflow: hidden;
  margin-top: 18px;
  font-size: 18px;
`

export const StyledDialog = styled.div`
  width: 500px;
  height: 60vh;
  padding: 2em;

  .verify-container {
    margin-top: 20%;
  }

  h2 {
    margin-bottom: 2em;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

export const StyledH2 = styled.h2`
  margin: 0px;

  div {
    margin: auto;
  }
`

export const StyledRestrictedLinkH2 = styled.h2`
  width: 350px;
  overflow: hidden;
  font-size: 18px;
`

export const StyledLoginButton: any = withStyles({
  root: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    filter: 'drop-shadow(1px 1px 2px #333)',
    padding: '0',
    fontFamily: 'Maven Pro, sans-serif',
  },
})(Button)

export const StyledTextField: any = withStyles({
  root: {
    marginBottom: '18px',
    width: '75%',
  },
})(TextField)

export const StyledLoginButtonContainer = styled.div`
  margin-bottom: 0.5em;
`

export const StyledLinkAccessField: any = withStyles({
  root: {
    margin: '18px 0 18px 0',
  },
})(TextField)
